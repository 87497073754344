.sign_page html,
body {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    color: #3d3d3d;

}

.sign_in_cus {
    overflow: auto;
    /* height: 100vh; */
}

@media screen and (max-width:1400px) {
    .sign_in_cus {
        height: 100vh;
    }
}

.sign_page .ft-600 {
    font-weight: 600;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.sign_page input {
    font-family: 'inter';
}

.sign_page input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sign_page .cp {
    cursor: pointer;
}

.sign_page .ms-clr {
    color: #4D6EE3;
}

.sign_page .txt-normal {
    font-size: 18px;
    font-weight: 600;
}

.sign_page .form-container .box {
    border: 1px solid #D9DCE6;
    box-shadow: 0px 2px 5px 0px #0000000D;
    border-radius: 18px;
    padding: 30px 10px 40px;
}

.sign_page .form-container .box img {
    margin-top: -110px;
    margin-bottom: 50px;
}

.sign_page .form-wrap {
    min-height: 100vh;
}

.sign_page .text-align-c {
    text-align: center;
}

.sign_page .whole-container .mt-30 {
    margin-top: 30px;
}

.sign_page .whole-container .mt-10 {
    margin-top: 10px;
}

.sign_page .whole-container .mt-20 {
    margin-top: 20px;
}

.sign_page .whole-container .mt-40 {
    margin-top: 40px !important;
}

.sign_page .mt-40 {
    margin-top: 40px;
}

.sign_page .mt-30 {
    margin-top: 30px;
}

.sign_page .mrg-cen {
    margin: 0 auto;
}

.sign_page .form-container {
    max-width: 440px;
    width: 90%;
    margin: 0 auto;
}

.sign_page .form-container h1 {
    text-align: center;
    color: #0E085C;
    font-weight: 700;
    font-size: 30px;
}

.sign_page .sign-form .forms-field-btn label {
    display: inline-flex;
}

.sign_page .peer:checked~.peer-checked\:bg-blue-600 {
    background-color: #4D6EE3;
}

.sign_page .after\:w-3:after {
    width: 0.8rem;
}

.sign_page .after\:h-3:after {
    height: 0.8rem;
}

/* .sign_page .form-container h1 span {
    display: block;
    color: #86849E;
    font-weight: 500;
    font-size: 18px;
    line-height: .8;
} */
/* Firefox */

.sign_page input[type=number] {
    -moz-appearance: textfield;
}

.sign_page em {
    font-style: normal;
}

.sign_page .whole-container p,
.whole-container h3,
.whole-container h4 {
    margin: 0;
}

.sign_page .flex-prop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.sign_in_cus {
    padding-top: 50px;
    padding-bottom: 50px;
}

.sign_page .login-cont-inr,
.log-address-inr {
    width: 100%;
    height: 100vh;
}

.sign_page .log-address-inr {
    width: 100%;
    background: #b9bcc6;
    justify-content: center;
}

.sign_page .login-cont-right,
.login-cont-left {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.sign_page .login-cont-left {
    /* padding: 10px; */
    padding: 0px;
    border-radius: 10px;
    text-align: center;
}

.sign_page .login-cont-right {
    height: calc(100vh);
    overflow: hidden;
    /* overflow-y: auto; */
}

.sign_page .log-left-inr {
    background: #F9FAFD;
    width: 100%;
    height: 100%;
    text-align: center;
}

.sign_page .login-cont-right .login-cont-inner {
    width: 100%;
    height: auto !important;
}

.sign_cus_page .sign_page .login-cont-right .login-cont-inner {
    padding-top: 50px;
    padding-bottom: 50px;
}

.sign_page .log-left-inr h3 {
    letter-spacing: -0.48px;
    font-size: 26px;
    color: #4D6EE3;
    font-weight: bold;
}

.sign_page .log-left-inr span {
    font-size: 14px;
    color: #131D58;
}

.sign_page .sign-inp h1 {
    max-width: 417px;
    width: 100%;
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: bold;
}

.sign_page .sign-inp .num-cont button {
    position: relative;
    width: 90px;
    border: 1px solid #D9DCE6;
    background: #FFF;
    padding: 14px;
    border-radius: 8px;
    text-align: left;
    margin-top: 0;
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.sign_page .sign-inp .num-cont button:after {
    content: '';
    position: absolute;
    content: "";
    right: .8rem;
    top: calc(55% - 0.2rem);
    transform: translateY(-50%) rotate(45deg);
    width: 0.4rem;
    height: 0.4rem;
    border-right: .12rem solid #3d3d3d;
    border-bottom: .12rem solid #3d3d3d;
    transition: .2s;
}

.sign_page .sign-inp .num-cont button.active::after {
    transform: translateY(0%) rotate(225deg);
}

.sign_page .num-box {}

.sign_page .sign-inp .num-cont img {
    padding: 0 10px;
}

.sign_page .sign-inp .arrow {
    position: relative;
    top: -3px;
}

/* .sign_page  .arrow::after,
   .next-arrow {
       content: "";
       position: absolute;
       width: 6px;
       height: 6px;
       border-top: 1px solid #000;
       border-right: 1px solid #000;
       transform: rotate(135deg);
       transition: transform .3s ease;
       right: -15px;
       top: 2px;
   } */

.sign_page .next-arrow {
    position: relative;
    display: inline-block;
    transform: rotate(45deg);
    top: 0;
    right: -5px;
    border-top: 1px solid #FFF;
    border-right: 1px solid #FFF;
}

.sign_page .sign-form .forms-fields-text {
    max-width: 440px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.sign_page .sign-form .forms-fields-text input {
    width: 100%;
    font-size: 13px !important;
    letter-spacing: unset;
    width: 100%;
    padding-left: 45px;
}

.sign_page .add_style_input input {
    padding: 14px 0 14px 44px !important;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 0;
    transition: all 0.4s ease;
}

.sign_page .differ_input input {
    padding: 16px 0 16px 0px !important;
}

.sign_page .whole-container .forms-fields-text input:focus {
    border-color: #485CF0;
    outline: none;
}

.sign_page .whole-container input::placeholder {
    color: #A9AFC3;
}

.sign_page .num-cont p {
    width: calc(100% - 100px);
}

.sign_page .label-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
    margin-right: 10px;
}

.sign_page .forms-fields-text .label-container {
    margin-bottom: 0;
}

.sign_page .tick-ele {
    float: left;
    display: block;
    margin: 0 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: 1px solid #3d3d3d;
    position: relative;
}

.sign_page .circle-ele {
    float: left;
    display: block;
    margin: 0 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #E8EAF2;
    position: relative;
}

.sign_page .sp-selected .label-containerinput:checked~.circle-ele {
    background-color: #fff;
    border: 1px solid;
}

.sign_page .label-containerinput:checked~.tick-ele {
    background-color: #fff;
    border: 1px solid;
}

/* Create the checkmark/indicator (hidden when not checked) */

.sign_page .tick-ele:after,
.circle-ele:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.sign_page .label-container input:checked~.tick-ele:after,
.sp-selected .label-container input:checked~.circle-ele:after {
    display: block;
}

.sign_page .label-container input:checked~.tick-ele {
    background: #4D6EE3;
    border-color: #4D6EE3;
}

.sign_page .label-container input:checked~.circle-ele {
    background-color: #fff;
}

.sign_page .label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;
}

.sign_page .label-container .circle-ele:after {
    content: '';
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4D6EE3;
}

.sign_page .tick-ele:after {
    /* content: '';
    display: inline-block;
    width: 2.5px;
    height: 6px;
    border: solid #fff;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(44deg);
    position: absolute;
    top: 4px;
    left: 6px;
    display: none; */
    content: '';
    display: block;
    width: 4px;
    height: 7px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(40deg);
    position: absolute;
    top: 4px;
    left: 7px;
}

.sign_page .whole-container span,
.sign-form div label {
    font-size: 13px;
}

.sign_page .sign-form div label {
    display: block;
    margin-bottom: 12px;
}

.sign_page .whole-container span a {
    color: #4D6EE3;
}

.sign_page .verify-btn {
    background: #4D6EE3;
    max-width: 440px;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 19px 0;
    color: #fff;
    margin-top: 15px;
    font-weight: 800;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 14px;
}

/* otp section */

.sign_page .login-otp-inr .login-cont-right {
    text-align: center;
}

.sign_page .otp-right-inr p {
    color: #373943;
    font-size: 16px;
    font-weight: 600;
    margin: 9px 0 5px;
}

.sign_page .opt-boxes {
    margin-top: 40px;
    /* max-width: 317px; */
    width: 100%;
    margin: 40px 10px;
    justify-content: space-evenly !important;
}

.sign_page .whole-container .opt-boxes input {
    width: 60px;
    height: 60px;
    padding: 0;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    border: 1px solid #D9DCE6;
    border-radius: 12px;
}

.sign_page .otp-right-inr {
    width: 100%;
}

.sign_page .log-address-cont {
    max-width: 801px;
    width: 100%;
    background: #FFF;
    position: relative;
    border-radius: 10px;
}

.sign_page .log-address-top {
    padding: 25px;
}

.sign_page .address-top1 {
    max-width: 165px;
    width: 100%;
}

.sign_page .scl-name {
    font-size: 20px;
    color: #181558;
}

.sign_page .log-address-top p {
    font-size: 18px;
    font-weight: 600;
    color: #373943;
}

.sign_page .log-address-cont h3 {
    font-size: 22px;
    text-align: center;
    margin: 10px 0 40px;
    font-weight: 600;
}

.sign_page .box-container {
    min-height: 300px;
}

.sign_page .log-address-inr input {
    width: 100%;
    display: block;
    margin: 40px auto 200px;
}

.sign_page .log-address-inr .rang-bar {
    display: block;
    width: 100%;
    height: 2px;
    background: #D9DCE6;
    position: absolute;
    left: 0;
}

.sign_page .progress-bar {
    width: 100%;
    background-color: #D9DCE6;
    height: 2px;
    border-radius: 5px;
    overflow: hidden;
}

.sign_page .progress-bar-fill {
    background-color: #4D6EE3;
    height: 100%;
    transition: width 0.3s ease-in-out;
    width: 20%;
}

.sign_page .next-btn,
.back-btn {
    width: 84px;
    padding: 10px 0;
    background: #4D6EE3;
    border: 0;
    color: #FFF;
    font-weight: 600;
    position: relative;
    float: right;
    margin-top: 30px;
    border-radius: 6px;
    cursor: pointer;
}

.sign_page .next-btn:after {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    left: 8px;
    top: 0;
    display: inline-block;
    position: relative;
    transition: all 0.4s ease;
}

.sign_page .next-btn:not([disabled]):hover:after {
    left: 10px;
}

.sign_page .back-btn:not([disabled]):hover:before {
    right: 6px;
}

.sign_page .back-btn {
    float: left;
    background: #FFF;
    color: #000;
    border: 1px solid #D7D9DD;
    border-radius: 6px;
    font-weight: 500;
}

.sign_page .back-btn:before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(-132deg);
    font-weight: 500;
    margin-right: 10px;
    top: -1px;
    display: inline-block;
    position: relative;
    transition: all 0.4s ease;
    right: 0;
}

.sign_page .back-btn .next-arrow {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(-135deg);
    left: -5px;
    top: -1px;
}

.sign_page .scl-size-cont button,
.scl-prg button {
    width: auto;
    height: 34px;
    background: #FFF;
    border: 1px solid #D9DCE6;
    border-radius: 8px;
    cursor: pointer;
    color: #373943;
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_page .scl-size-cont button.sp-selected,
.scl-prg button.sp-selected {
    border: 1px solid #4D6EE3;
    border-radius: 8px;
    color: #4D6EE3;
    box-shadow: 0px 2px 5px 0px #4D6EE338;
    background-color: #4D6EE3;
    color: #fff;
}

.sign_page .scl-prg button .tick-ele:after {
    border-color: #4D6EE3;
    top: 4px;
    left: 6px;
}

.sign_page .scl-prg button .tick-ele:after {}

.sign_page .scl-prg button .tick-ele {
    border-color: #D9DCE6;
}

.sign_page .scl-prg button .label-container input:checked~.tick-ele {
    background-color: #fff;
}

.sign_page .size-cont2,
.size-cont1 {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
}

.sign_page .size-cont1 {
    max-width: 450px;
    margin-bottom: 20px;
}

.sign_page .scl-size-cont {
    margin: 40px 0 150px;
}

.sign_page .program-size button {
    width: 114px;
}

.sign_page .program-size {
    padding-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
}

.sign_page .select-box .options.active {
    display: block;
}

.sign_page .select-box ol li.hide {
    display: none;
}

.sign_page .options {
    position: absolute;
    top: 4.7rem;
    width: 100%;
    background-color: #fff;
    border-radius: .5rem;
    display: none;
    width: 100%;
    border: 1px solid #D9DCE6;
    z-index: 2;
}

.sign_page .options ol {
    list-style: none;
    max-height: 12rem;
    overflow: overlay;
    padding: 0;
}

.sign_page .select-box ol li:not(:last-child) {
    border-bottom: .1rem solid #eee;
}

.sign_page .select-box ol li {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.sign_page .select-box ol li:hover {
    background-color: #F4F5F9;
}

.sign_page .select-box ol li.option div {
    display: flex;
    gap: 5px;
}

.sign_page .select-box ol li.option em {
    font-size: 13px;
}

.sign_page .search-box {
    margin-top: 0;
}

.sign_page .terms-cont {
    display: flex;
    align-items: center;
}

.sign_page button:disabled,
button[disabled] {
    opacity: 0.6;
    cursor: default;
}

/*  */

.sign_page img {
    max-width: 100%;
}

.sign_page .sp-search-school {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
}

.sign_page .search-list {
    position: absolute;
    top: 55px;
    border: 1px solid #D9DCE6;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    max-width: 630px;
    left: 0;
    max-height: 16rem;
    overflow: overlay;
    background-color: #fff;
    z-index: 2;
}

.sign_page .search-li img,
.sch-text-op img {
    width: 36px;
}

.sign_page .dis-block {
    display: block;
}

.sign_page .search-li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 6px;
    border-radius: 6px;
    cursor: pointer;
}

.sign_page .search-li:hover {
    background-color: #F4F5F9;
}

.sign_page .search-li .title-text {
    color: #373943;
    font-size: 13px;
}

.sign_page .search-li .title-text em {
    margin-bottom: 5px;
}

.sign_page .search-sch-list {
    border: 1px solid #D9DCE6;
    padding: 18px 15px;
    border-radius: 6px;
}

.sign_page .sch-text-op {
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    left: 11px;
    background-color: #fff;
    font-size: 13px;
    gap: 10px;
    width: 86%;
}

.sign_page #schoolList {
    display: none;
}

.sign_page #schoolList.active {
    display: block;
}

.sign_page .close-search {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 20px;
    height: 20px;
    font-size: 0px;
    border-radius: 50%;
    background-color: #F3F3F4;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.sign_page .close-search:after {
    content: '';
    /* background: url(images/close.svg) no-repeat; */
    background-size: 100%;
    width: 8px;
    height: 8px;
    display: block;
}

.sign_page .pos {
    position: relative;
}

.sign_page .close-search.active {
    display: flex;
}

.sign_page .sp-progress-btn {
    padding: 0 25px 25px;
}

.sign_page .box-container>div {
    display: none;
}

.sign_page .box-container>div.sp-sec-active {
    display: block;
}

.sign_page .forms-field-btn {
    margin: unset;
}

.sign_page .form-bottom {
    text-align: center;
}

.sign_page .form-bottom p {
    font-weight: 500;
    font-size: 13px;
}

.sign_page .form-otherlogins {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 20px;
}

.sign_page .form-otherlogins span {
    border-radius: 8px;
    padding: 11px 28px;
    border: 1px solid #D9DCE6;
    min-width: 140px;
    font-weight: 600;
    color: #26134B;
    font-size: 13px;
    justify-content: center;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
}

.sign_page .form-otherlogins span:before {
    content: '';
    background-image: url(../images/sprite-sp.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 14px;
    background-size: 242px auto;
    background-position: -117px -19px;
    margin-right: 8px;
}

.sign_page .form-otherlogins span.sp-microsoft-acc:before {
    background-position: -156px -19px;
}

.sign_page .toggleswitch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.sign_page .sign-form .toggleswitch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 20px;
    height: 11px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-bottom: 0;
    margin-right: 10px;
}

.sign_page .toggleswitch label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 9px;
    height: 9px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.sign_page .toggleswitch input:checked+label {
    background: #3E52C1;
}

.sign_page .toggleswitch input:checked+label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.sign_page .text-fwd a {
    font-size: 13px;
    text-decoration: none;
}

.sign_page [class^="sp-icon"] {
    position: absolute;
    left: 14px;
    /* top: 55%; */
}

.sign_page [class^="sp-icon"]:before {
    content: '';
    width: 16px;
    height: 18px;
    background-image: url(../images/sprite-sp.svg);
    background-repeat: no-repeat;
    background-size: 242px auto;
    display: block;
}

.sign_page .sp-icon-mail:before {
    background-position: -71px -17px;
    width: 18px;
}

.sign_page .sp-icon-pw:before {
    background-position: -24px -17px;
}

.sign_page [multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
    border-radius: 8px !important;
}

.sign_page .whole-container .forms-fields-text input {
    padding: 14px 0 14px 15px;
}

.sign_page [type='text'],
[type='url'],
[type='password'],
[type='number'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
select {
    line-height: 1rem;
    font-size: 0.8125em !important;
}

.sign_page .sign-inp h1 {
    line-height: 1.3;
}

.sign_page .opt-boxes.flex-prop [type='text'],
[type='url'],
[type='month'],
/* [type='search'], */

[type='tel'],
[type='time'],
[type='week'],
[multiple],
select {
    font-size: 16px !important;
}

.sign_page .scl-size-cont button,
.scl-prg button {
    white-space: nowrap;
}

/* -------------------------------------------------------------------- */
/*  
input_forsign,icon_input, 
*/

.sign_page .input_forsign [class^="sp-icon"] {
    top: unset;
}

.sign_page .icon_input {
    display: flex;
    align-items: center;
}

.sign_page .icon_input input {
    padding: 16px 0 16px 15px !important;
    padding-left: 44px !important;
    line-height: 1;
    font-size: 100%;
}

/* .sign_page .error-message {
    color: red;
    margin-top: 5px;
    font-size: 12px;
}

.sign_page .error {
    border: 1px solid red;
}

.sign_page .error-border {
    border: 1px solid red;
} */

/* .error-message {
    color: red;
    margin-top: 5px;
    font-size: 12px;
} */