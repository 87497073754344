* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}

.highlight-color {
    color: var(--sp-theme-color2);
}


/* body {
    line-height: 1;
} */

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

:focus {
    outline: 0;
}

ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

.hide {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    color: #000;
    background-color: #fff;
}

a {
    text-decoration: none;
}

.aldrichft {
    font-family: 'aldrichregular';
}

img {
    width: 100%;
}

.flx {
    display: flex;
}

button {
    cursor: pointer;
}

.flex-1 {
    flex: 0 0 10%;
}

.flex-2 {
    flex: 0 0 15%;
}

.flex-4 {
    flex: 0 0 15%;
}

.flex-3 {
    flex: 0 0 30%;
}

.flex-5 {
    flex: 0 0 5%;
}

.fwrap {
    flex-wrap: wrap;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.gap-10 {
    gap: 10px;
}

select,
input,
button {
    background: none;
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

em {
    font-style: normal;
}

.ft-wt100 {
    font-weight: 100;
}

.ft-wt200 {
    font-weight: 200;
}

.ft-wt300 {
    font-weight: 300;
}

.ft-wt400 {
    font-weight: 400;
}

.ft-wt500 {
    font-weight: 500;
}

.ft-wt600 {
    font-weight: 600;
}

.ft-wt700 {
    font-weight: 700;
}

.mr-lf30 {
    margin-left: 30px;
}

.ft-12 {
    font-size: 0.75em;
}

.ft-10 {
    font-size: 0.625em;
}

.ft-11 {
    font-size: 0.6875em;
}

.ft-13 {
    font-size: 0.8125em;
}

.ft-14 {
    font-size: 0.875em;
}

.ft-15 {
    font-size: 0.935em;
}

.ft-17 {
    font-size: 17px;
}

.ft-18 {
    font-size: 1.125em;
}

.mr-lf10 {
    margin-left: 10px;
}

.mr-rt10 {
    margin-right: 10px;
}

.mt-10 {
    margin-top: 10px!important;
}

.clr-theme1 {
    color: var(--sp-theme-color2);
}

.clr-grey {
    color: #495159;
}

.line-h-4 {
    line-height: 1.4;
}

:root {
    --sp-clr-red: #F94A37;
    --sp-border-clr: #E8EAF2;
    --sp-search-clr: #F4F5F9;
    --sp-ft-clr: #344054;
    --sp-normal-clr: #373943;
    --sp-blue-clr: #4D6EE3;
    --sp-divider-clr: #E9E9F3;
    --sp-hg-clr: #E4E8FA;
    --sp-br-clr: #D7D9DD;
    --sp-secondary2-clr: #6B6F8C;
    --sp-border-menu: #D9DCE6;
    --sp-tb-color: #1B252F;
    --sp-tag-color: #EFF2F4;
    --sp-theme-color2: #485CF0;
    --sp-border-secondary-clr: #EBECF5;
    --sp-green-clr: #01a768;
    --sp-theme-clr1: #FAFBFC;
    --sp-theme-clr2: #ECF1FF;
    --sp-secondary-theme-clr: #6B85EE;
    --sp-theme-clr3: #E1F9ED;
    --sp-status-clr: #24B16D;
    --sp-theme-clr4: #686C7B;
    --sp-secondary-clr3: #E1E4EA;
    --sp-dark-blue: #101B7C;
    --sp-light-indigo: #95A4FC;
    --sp-light-mint: #BAEDBD;
    --sp-dark-blk: #1C1C1C;
    --sp-secd-blue: #B1E3FF;
    --sp-secd-cyan: #A8C5DA;
    --sp-secd-light-green: #A1E3CB;
    --sp-secd-gray: #8C8C8C;
    --sp-orange-clr: #FA9884;
    --sp-pink-clr: #FF69A3;
    --sp-secd-blue-clr: #7B81CC;
}