@font-face {
  font-family: 'icomoon';
  src: url('../css/font/icomoon.eot');
  src: url('../css/font/icomoon-1.eot') format('embedded-opentype'),
    url('../css/font/icomoon.ttf') format('truetype'),
    url('../css/font/icomoon.woff') format('woff'),
  ;
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Phone-Voice:before {
  content: "\e941";
}

.icon-pluss:before {
  content: "\e941";
}

.icon-tik:before {
  content: "\e942";
  color: #24b16d;
}

.icon-send:before {
  content: "\e941";
}

.icon-Gift:before {
  content: "\e93f";
}

.icon-Group:before {
  content: "\e940";
  color: #1bc400;
}

.icon-Favourite_Selected:before {
  content: "\e900";
}

.icon-Enquiries:before {
  content: "\e901";
}

.icon-Dropdown_Select:before {
  content: "\e902";
}

.icon-Dashboard:before {
  content: "\e903";
}

.icon-Close:before {
  content: "\e904";
  margin: 0 !important;
}

.icon-Calendar:before {
  content: "\e905";
}

.icon-Button_Present:before {
  content: "\e906";
}

.icon-Button_Leave:before {
  content: "\e907";
}

.icon-Button_Absent:before {
  content: "\e908";
}

.icon-Bus-Tracking:before {
  content: "\e909";
}

.icon-Attandance:before {
  content: "\e90a";
}

.icon-Message:before {
  content: "\e90b";
}

.icon-Logout:before {
  content: "\e90c";
}

.icon-List_Present .path1:before {
  content: "\e90d";
  color: #24B16D;
}

.icon-List_Present .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-List_Leave .path1:before {
  content: "\e90f";
  color: #FC9612;
}

.icon-List_Leave .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: #FC9612;
}

.icon-List_Leave .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-List_Leave .path4:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-List_Absent .path1:before {
  content: "\e913";
  color: rgb(27, 37, 47);
}

.icon-List_Absent .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-List_Absent .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: #F14B4B;
}

.icon-List_Absent .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: #F14B4B;
}

.icon-List_Absent .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-List_Absent .path6:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Like-1:before {
  content: "\e919";
  margin: 0!important;
}

.icon-Like:before {
  content: "\e91a";
}

.icon-Invite_Parents:before {
  content: "\e91b";
}

.icon-Info_Modal:before {
  content: "\e91c";
  margin: 0 !important;
}

.icon-Import:before {
  content: "\e91d";
}

.icon-Help:before {
  content: "\e91e";
}

.icon-Food:before {
  content: "\e91f";
}

.icon-Fees-Management:before {
  content: "\e920";
}

.icon-Favourite_Unselected:before {
  content: "\e921";
}

.icon-Sleep:before {
  content: "\e922";
}

.icon-Settings:before {
  content: "\e923";
}

.icon-Search:before {
  content: "\e924";
}

.icon-Roles-and-Permission:before {
  content: "\e925";
}

.icon-Recycle_Modal:before {
  content: "\e926";
  margin: 0 !important;
}

.icon-Programs_Create:before {
  content: "\e927";
}

.icon-Program_2:before {
  content: "\e928";
}

.icon-Program_1:before {
  content: "\e929";
}

.icon-Onboard_Previous:before {
  content: "\e92a";
  margin: 0 !important;
}

.icon-Onboard_Next:before {
  content: "\e92b";
  margin: 0 !important;
}

.icon-Notification:before {
  content: "\e92c";
}

.icon-Move_Recycle:before {
  content: "\e92d";
}

.icon-Move_New-Program:before {
  content: "\e92e";
}

.icon-Move-to:before {
  content: "\e92f";
}

.icon-More:before {
  content: "\e930";
}

.icon-Upgrade:before {
  content: "\e931";
}

.icon-Timetable:before {
  content: "\e932";
}

.icon-Time:before {
  content: "\e933";
}

.icon-Student_Edit:before {
  content: "\e934";
  margin: 0 !important;
}

.icon-Student:before {
  content: "\e935";
}

.icon-Staff-and-Parent-Details:before {
  content: "\e935";
}

.icon-Student-List:before {
  content: "\e936";
}

.icon-Staff_Invite:before {
  content: "\e937";
}

.icon-Arrow_Left:before {
  content: "\e938";
}

.icon-Staff:before {
  content: "\e939";
}

.icon-Add_Studend-and-Staff:before {
  content: "\e93a";
}

.icon-Arrow_Down:before {
  content: "\e93b";
}

.icon-Add-Food--Sleep:before {
  content: "\e93c";
}

.icon-Activity:before {
  content: "\e93d";
}

.icon-Arrow_Right:before {
  content: "\e93e";
}